<template>
  <li class="p-3 bg-gray-100 rounded-md mb-2">
    <div v-if="isEditing">
      <input
        v-model="localText"
        class="w-full p-2 border border-gray-300 rounded-md"
        type="text"
        @blur="saveEdit"
        @keyup.enter="saveEdit"
      />
    </div>
    <div v-else>
      <p class="cursor-pointer" @click="toggleExpand">
        {{
          expanded
            ? localText
            : localText.slice(0, 50) + (localText.length > 50 ? '...' : '')
        }}
      </p>
      <button class="text-blue-500 text-sm mt-2 underline mr-4" @click="startEdit">
        Edit
      </button>
      <button class="text-red-500 text-sm mt-2 underline" @click="emitRemoveNote">
        Remove
      </button>
    </div>
  </li>
</template>

<script setup lang="ts">
import { ref } from 'vue';

interface NoteProps {
  id: number;
  text: string;
  expanded?: boolean;
}

// Define properties passed to this component
const props = defineProps<NoteProps>();

interface NoteEmits {
  (event: 'removeNote', id: number): void;

  (event: 'updateNote', value: { id: number; text: string }): void;
}

// Define emits to communicate with parent component
const emit = defineEmits<NoteEmits>();

// Local state for editing and expansion
const localText = ref<string>(props.text);
const expanded = ref<boolean>(props.expanded ?? false);
const isEditing = ref<boolean>(false);

// Emit event to remove the note
const emitRemoveNote = () => {
  emit('removeNote', props.id);
};

// Toggle expanded state
const toggleExpand = () => {
  expanded.value = !expanded.value;
};

// Start editing the note
const startEdit = () => {
  isEditing.value = true;
};

// Save the edited note and emit event
const saveEdit = () => {
  isEditing.value = false;
  emit('updateNote', { id: props.id, text: localText.value });
};
</script>
