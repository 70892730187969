<template>
  <ClientOnly>
    <div class="shadow-2xl max-w-screen-md p-4 flex flex-col justify-center gap-2">
      <h2 class="text-center text-2xl">Example - VueX Access - Options API</h2>
      <div>[GETTER] Current Auth user (minified)</div>
      <div class="font-mono">{{ firebaseUserSimplified }}</div>
      <div class="text-green-900">[GETTER] routerHistory/getRouterHistory</div>
      <div class="text-green-900 font-mono">{{ routerHistoryGetRouterHistory }}</div>
      <div class="text-blue-900">[STATE] routerHistory/routerHistory</div>
      <div class="text-blue-900 font-mono">{{ routerHistoryRouterHistoryState }}</div>
      <button
        class="py-0.5 px-1 shadow-sm border"
        @click="onClickPerformActionRouterHistoryAddPageUrl"
      >
        [ACTION] routerHistory/addPageUrl
      </button>
      <button
        class="py-0.5 px-1 shadow-sm border"
        @click="onClickPerformMutationRouterHistoryAddPreviousPageUrl"
      >
        [MUTATION] routerHistory/ADD_PREVIOUS_PAGE_URL
      </button>
    </div>
  </ClientOnly>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  name: 'ExampleVuexOptions',
  computed: {
    ...mapGetters({
      routerHistoryGetRouterHistory: 'routerHistory/getRouterHistory',
      firebaseUser: 'users/firebaseUser',
    }),
    routerHistoryRouterHistoryState() {
      return this.$store?.state.routerHistory.routerHistory;
    },
    firebaseUserSimplified() {
      if (!this.firebaseUser) return null;
      return {
        uid: this.firebaseUser.uid,
        email: this.firebaseUser.email,
        displayName: this.firebaseUser.displayName,
      };
    },
  },
  methods: {
    ...mapActions({
      actionRouterHistoryAddPageUrl: 'routerHistory/addPageUrl',
    }),
    ...mapMutations({
      mutationRouterHistoryAddPreviousPageUrl: 'routerHistory/ADD_PREVIOUS_PAGE_URL',
    }),
    onClickPerformActionRouterHistoryAddPageUrl() {
      const pageUrl = 'https://hello.world';

      this.actionRouterHistoryAddPageUrl(pageUrl);
    },
    onClickPerformMutationRouterHistoryAddPreviousPageUrl() {
      const pageUrl = 'https://hello.world';

      this.mutationRouterHistoryAddPreviousPageUrl(pageUrl);
    },
  },
});
</script>

<style scoped lang="scss"></style>
